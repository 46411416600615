<template>
  <div class="add-apartment">
    <h1 class="admin-title">{{$t('New Apartment')}}</h1>
    <ValidationObserver ref="validator">
      <form class="add-apartment-form" @submit.prevent>
        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Name">
              <RFInput label="Name" v-model="apartment.name"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Description">
              <RFInput label="Description" v-model="apartment.description"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Type">
              <RFSelect top-label="Type" v-model="apartment.typeId" @input="apartment.typeId = $event" :list="accommodationTypeList"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Square Meters">
              <RFInput label="Square Meters" v-model="apartment.sqMeters" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number Of Person">
              <RFInput label="Number Of Person" type="number" :min="1" v-model="apartment.numOfPerson"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Num Of Bathrooms">
              <RFInput label="Num Of Bathrooms" type="number" :min="1" v-model="apartment.numOfBathrooms"/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Min Stay Month" type="number" :min="1" v-model="apartment.minStayMonth"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Amenities" v-model="editedAmenities" :list="amenities" property="name"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="House Rules" v-model="editedHouseRules" :list="houseRules" property="name"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInputBeds v-model="apartment.beds"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Building Characteristics" v-model="editedCharacteristics" :list="characteristics" property="name"/>
          </b-col>
          <b-col>
            <RFInput label="Business Unit" v-model="apartment.businessUnit"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Certificazione Energetica" v-model="apartment.certEnergetica" />
          </b-col>
          <b-col>
            <RFInput label="Item Code" v-model="apartment.itemCode" />
          </b-col>
          <b-col>
            <RFInput label="Piano dei Conti" v-model="apartment.pianoDeiConti" />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Admin Comment" v-model="apartment.adminComment" :value="apartment.adminComment"/>
          </b-col>
          <b-col>
            <RFMultipleInputAutocomplete label="Owners" v-model="editedOwners" :list="owners" property="name"/>
          </b-col>
          <b-col>
            <RFCheckbox class="mt-4" label="Has gas" v-model="apartment.hasGas" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="auto">
            <RFCheckbox class="mt-4" label="Use contract template with private owner" v-model="apartment.ownerContractTemplate" />
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Address')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Country">
              <RFInputAutocomplete label="Country" :value="apartment.addressCountry" :items="countries" v-model="apartment.addressCountry"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="City">
              <RFInput label="City" v-model="apartment.addressCity"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Zip Code">
              <RFInput label="Zip Code" v-model="apartment.addressZipCode" is-numeric/>
            </RFValidation>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFValidation rules="required" name="Street">
              <RFInput label="Street" v-model="apartment.addressStreet"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Number">
              <RFInput label="Number" v-model="apartment.addressNumber" is-numeric/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFInput label="Floor" v-model="apartment.floor"/>
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Amounts')}}</h4>

        <b-row>
          <b-col>
            <RFValidation rules="required" name="Monthly Amount">
              <RFCurrencyInput label="Monthly Amount" v-model="apartment.monthlyAmount"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFValidation rules="required" name="Deposit Amount">
              <RFCurrencyInput label="Deposit Amount" v-model="apartment.depositAmount"/>
            </RFValidation>
          </b-col>
          <b-col>
            <RFCurrencyInput label="Service Fees" v-model="apartment.serviceFees"/>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4">
            <RFValidation rules="required" name="Oneri Amount">
              <RFCurrencyInput label="Oneri Amount" v-model="apartment.oneriAmount"/>
            </RFValidation>
          </b-col>
          <b-col cols="4">
            <RFValidation rules="required" name="Service Amount">
              <RFCurrencyInput label="Service Amount" v-model="apartment.serviceAmount"/>
            </RFValidation>
          </b-col>
          <b-col cols="auto">
              <RFCheckbox class="mt-4" label="Pay only with bank transfer" v-model="apartment.bankTransferMonthlyPayments" />
          </b-col>
        </b-row>

        <h4 class="admin-subtitle mt-5">{{$t('Cadastral Data')}}</h4>

        <b-row>
          <b-col>
            <RFInput label="Category" v-model="apartment.catastoCategory"/>
          </b-col>
          <b-col>
            <RFInput label="Foglio" v-model="apartment.catastoFoglio"/>
          </b-col>
          <b-col>
            <RFInput label="Particella" v-model="apartment.catastoParticella"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <RFInput label="Subalterno" v-model="apartment.catastoSubalterno"/>
          </b-col>
          <b-col>
            <RFCurrencyInput label="Rendita" v-model="apartment.catastoRendita"/>
          </b-col>
          <b-col>
            <RFInput label="Vani" v-model="apartment.catastoVani"/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>

    <b-row class="building-section">
      <b-col class="my-4">
        <h2 class="admin-subtitle">{{$t('Apartment Images')}}</h2>
      </b-col>
      <b-col cols="12" class="px-3">
        <AdminImageList :selected-image-index="selectedImageIndex"
          @select="selectedImageIndex = $event" @images="images = $event" @planimetry="planimetryIndex = $event"
          min-height="300px" :accept-videos="true" :validate-file-size-limit="true"/>
      </b-col>
      <b-col class="my-4">
        <p class="admin-aclaration">{{$t(`Images and videos max size is ${fileSizeLimit} MB (Max size recommended: ${fileSizeLimitRecommended} MB)`)}}</p>
      </b-col>
    </b-row>
    <b-row class="justify-content-end mt-5">
      <b-col cols="auto">
        <RFButton class="mt-3" label="Save" background="#5CB86C" v-if="!loading" @on-click="save"/>
        <RFLoader class="mt-3" color="#24425B" :size="30" v-else/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import RFInput from '@/components/forms/RFInput'
import RFCurrencyInput from '@/components/forms/RFCurrencyInput'
import RFButton from '@/components/forms/RFButton'
import RFSelect from '@/components/forms/RFSelect'
import RFSelectItems from '@/components/forms/RFSelectItems'
import RFLoader from '@/components/forms/RFLoader'
import RFFile from '@/components/forms/RFFile'
import RFTextArea from '@/components/forms/RFTextArea'
import RFInputAutocomplete from '@/components/forms/RFInputAutocomplete'
import RFMultipleInputAutocomplete from '@/components/forms/RFMultipleInputAutocomplete'
import RFInputTranslations from '@/components/forms/RFInputTranslations'
import RFInputBeds from '@/components/forms/RFInputBeds'
import RFValidation from '@/components/forms/RFValidation'
import RFCheckbox from '@/components/forms/RFCheckbox'

import AdminImageList from '@/components/admin/AdminImageList'
import AdminInfoList from '@/components/admin/AdminInfoList'

import { apartmentService, ownerService } from '@/services/admin'
import { mapState } from 'vuex'
import { extractIds } from '@/helpers/functions'

export default {
  name: 'AdminAddApartment',
  components: {
    RFInput,
    RFCurrencyInput,
    RFButton,
    RFSelect,
    RFSelectItems,
    RFFile,
    RFLoader,
    RFTextArea,
    RFInputAutocomplete,
    RFMultipleInputAutocomplete,
    RFInputTranslations,
    RFInputBeds,
    RFValidation,
    RFCheckbox,

    AdminImageList,
    AdminInfoList,
  },
  data() {
    return {
      apartment: {
        name: null,
        description: null,
        typeId: null,
        numOfPerson: null,
        floor: null,
        sqMeters: null,
        numOfBathrooms: null,
        minStayMonth: null,
        ownerIds: [],

        monthlyAmount: null,
        depositAmount: null,
        oneriAmount: 50.0,
        serviceAmount: 50.0,
        serviceFees: null,
        bankTransferMonthlyPayments: false,
        ownerContractTemplate: false,

        addressCountry: null,
        addressCity: null,
        addressZipCode: null,
        addressNumber: null,
        addressStreet: null,

        amenitiesIds: [],
        houseRuleIds: [],
        characteristicIds: [],
        beds: {},
        hasGas: false,

        businessUnit: null,

        catastoCategory: null,
	      catastoFoglio: null,
	      catastoParticella: null,
	      catastoSubalterno: null,
	      catastoRendita: null,
	      catastoVani: null,
      },

      editedAmenities: [],
      editedHouseRules: [],
      editedCharacteristics: [],
      editedOwners: [],

      accommodationTypeList: [],
      images: [],
      selectedImageIndex: 0,
      planimetryIndex: null,
      loading: false,
      fileSizeLimit: 100, //In MB
      fileSizeLimitRecommended: 10, //In MB
    }
  },
  computed: {
    ...mapState({
      amenities: state => state.adminAmenityStore.amenities,
      houseRules: state => state.adminHouseRuleStore.houseRules,
      characteristics: state => state.adminCharacteristicStore.characteristics,
      storedCountries: state => state.countryStore.countries,
      owners: state => state.adminOwnerStore.owners,
      accommodationTypes: state => state.accommodationTypeStore.accommodationTypes,
    }),
    countries() {
      return this.storedCountries.map(country => country.name)
    }
  },
  beforeMount() {
    this.accommodationTypeList = this.accommodationTypes.map(item => ({ value: item.id, label: item.name }))
  },
  watch: {
    editedAmenities: {
      deep: true,
      handler() {
        this.apartment.amenitiesIds = extractIds(this.editedAmenities)
      }
    },
    editedHouseRules: {
      deep: true,
      handler() {
        this.apartment.houseRuleIds = extractIds(this.editedHouseRules)
      }
    },
    editedCharacteristics: {
      deep: true,
      handler() {
        this.apartment.characteristicIds = extractIds(this.editedCharacteristics)
      }
    },
    editedOwners: {
      deep: true,
      handler() {
        this.apartment.ownerIds = extractIds(this.editedOwners)
      }
    }
  },
  methods: {
    async save() {
      const isValid = await this.$refs.validator.validate()

      // Adjust orderIndex based on selectedImageIndex
      if (this.selectedImageIndex !== 0) {
        this.images.forEach((file, index) => {
          if (index === this.selectedImageIndex) {
            file.orderIndex = 0;
          } else if (index < this.selectedImageIndex) {
            file.orderIndex = index + 1;
          } else {
            file.orderIndex = index;
          }
        });
      } else {
        this.images.forEach((file, index) => {
          file.orderIndex = index;
        });
      }
      if (isValid) {
        this.loading = true
        this.apartment.numOfBathrooms = parseInt(this.apartment.numOfBathrooms)
        this.apartment.numOfPerson = parseInt(this.apartment.numOfPerson)
        this.apartment.sqMeters = parseInt(this.apartment.sqMeters)
        this.apartment.minStayMonth = parseInt(this.apartment.minStayMonth)

        const { data: apartmentId, status } = await apartmentService.addApartment(this.apartment)

        if (status === 200) {
          await this.addImages(apartmentId)

          this.$toasted.success(this.$i18n.t(`Apartment successfully added!`))
          this.$router.push({ name: 'apartment-list' })
        } else {
          this.$toasted.error(this.$i18n.t(`Error during add apartment`))
        }

        this.loading = false
      }
    },
    async addImages(apartmentId) {
      this.images.forEach(async (image, index) => {
        const form = new FormData()

        form.append('file', image)
        form.append('isPrimary', this.selectedImageIndex === index)
        form.append('orderIndex', image.orderIndex)

        if (this.planimetryIndex) {
          form.append('isPlan', this.planimetryIndex === index)
        }

        await apartmentService.addResource(apartmentId, form)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-apartment {
  .building-section {
    margin: 20px 0;
  }
}
</style>
